'use strict';

const util = require('../components/util');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessage(data) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append(
            '<div class="add-to-wishlist-messages "></div>'
        );
    }
    if ($('.wishlistTile').attr('data-loggedUser') === 'true') {
        $('.add-to-wishlist-messages')
            .append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');
    } else {
        $('.add-to-wishlist-messages')
            .append('<div class="add-to-wishlist-alert text-center ' + status + '"><a href="' + data.wishlistUrl + '">' + data.notLoggedMsg + '</a></div>');
    }

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 5000);
}

module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.wishlistTile.js-pdp-add-to-wishlist', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var button = $(this);
            var icon = $(this).find($('i'));
            var url = $(this).attr('href');
            var pid = $(this).closest('.product').data('pid');
            var optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
            var optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function (data) {
                    displayMessage(data);

                    button.removeClass('js-pdp-add-to-wishlist').addClass('js-pdp-remove-from-wishlist');
                    icon.removeClass('icon-heart-small').addClass('icon-heart-small-hover');
                },
                error: function (err) {
                    displayMessage(err);
                }
            });
        });
    },

    removeProductFromWishlist: function () {
        $('body').on('click', '.wishlistTile.js-pdp-remove-from-wishlist', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var url = $(this).data('removeurl');
            var button = $(this);
            var icon = $(this).find($('i'));
            var pid = $(this).closest('.product').data('pid');

            var newUrl = util.getPathFromUrl(url);

            $.spinner().start();
            $.ajax({
                url: `${newUrl}?pid=${pid}`,
                type: 'get',
                dataType: 'json',
                data: {},
                success: function (data) {
                    displayMessage(data);

                    button.removeClass('js-pdp-remove-from-wishlist').addClass('js-pdp-add-to-wishlist');
                    icon.removeClass('icon-heart-small-hover').addClass('icon-heart-small');
                },
                error: function (err) {
                    displayMessage(err);
                }
            });
        });
    }
};
