'use strict';

module.exports = {
    createPDPCarousel: function ($productContainer) {
        var slickMainCarousel = $productContainer.find('.js-product-main-carousel');
        var slickThumbnailsMainCarousel = $productContainer.find('.js-slider-nav-thumbnails');

        if (window.innerWidth < 992) {
            if ($(slickMainCarousel).hasClass('slick-initialized')) {
                $(slickMainCarousel).slick('unslick');
            }

            $(slickMainCarousel).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: false,
                dots: true,
                infinite: false
            });
        } else {
            if ($(slickMainCarousel).hasClass('slick-initialized')) {
                $(slickMainCarousel).slick('unslick');
            }

            if ($(slickThumbnailsMainCarousel).hasClass('slick-initialized')) {
                $(slickThumbnailsMainCarousel).slick('unslick');
            }

            $(slickMainCarousel).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                infinite: false,
                speed: 10,
                cssEase: 'linear',
                dots: false,
                asNavFor: '.slider-nav-thumbnails'
            });

            $(slickThumbnailsMainCarousel).slick({
                autoplay: false,
                slidesToShow: 6,
                slidesToScroll: 1,
                asNavFor: slickMainCarousel,
                arrows: false,
                dots: false,
                centerMode: false,
                focusOnSelect: true,
                variableWidth: false,
                vertical: true
            });
        }
    }
};
